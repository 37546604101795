.music {
  background-image: url("../../images/chair.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  padding: 300px 50px;
  background-position: center;
}

.buttons {
  margin-bottom: 30px;
}

.musicLink {
  background-color: #fff !important;
  padding: 8px 27px !important;
  text-decoration: none !important;
  color: #000 !important;
  margin: 10px 10px 10px 0px;
}

@media screen and (max-width: 480px) {
  .musicLink {
    background-color: #fff;
    padding: 8px 30px;
    text-decoration: none;
    color: #000;
    display: block;
    text-align: center;
  }
}
