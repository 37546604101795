.never {
  background-color: #34fbaa;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  padding: 40px 0px 40px 0px;
  background-position: top center;
}

.gn {
  background: #DB92FF;
  background: radial-gradient(circle, rgba(254,254,254,1) 0%, rgba(219,146,254,1) 44%, rgba(170,1,255,1) 87%, rgba(119,0,179,1) 100%);
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  padding: 40px 0px 40px 0px;
  /* margin-top:-6px; */
  background-position: top center;
}

.image {
  border-radius: 15px 10px 30px 50px;
  max-width: 400px;
  width: 100%;
  display: block;
  margin: 100px auto;
}

@media only screen and (max-width: 768px) {
  .gn {
    margin-top: -20px;
  }
}

@media only screen and (min-width: 768px) {
  .gn {
    margin-top: -20px;
  }
}

@media screen and (max-width: 480px) {
  .gn {
    margin-top: -100px;
  }
}
