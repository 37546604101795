.background {
  text-align: center;
  background-image: url("../../images/night.jpeg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  padding: 350px 50px 300px 50px;
  background-position: top center;
}

.sign {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.word {
  font-size: 5.6rem;
  text-align: center;
  line-height: 1;
  color: rgb(180, 254, 68);
  animation: neon 0.08s ease-in-out infinite alternate;
}

.button {
  background-color: #fff !important;
  padding: 20px 40px !important;
  text-decoration: none !important;
  color: #000 !important;
  margin: 40px 20px 0px 0px;
}

.button:hover {
  content: "SOLD OUT";
  background: rgb(180, 254, 68);
  background: linear-gradient(
    0deg,
    rgba(180, 254, 68, 1) 0%,
    rgba(26, 246, 247, 1) 100%
  );
}
/*-- Animation Keyframes --*/

@keyframes neon {
  from {
    text-shadow: 0 0 6px rgba(26, 246, 247, 1),
      0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52),
      0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78),
      0 0 54px rgba(30, 132, 242, 0.92);
  }
  to {
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
      0 0 30px rgba(202, 228, 225, 0.42), 0 0 12px rgba(30, 132, 242, 0.58),
      0 0 22px rgba(30, 132, 242, 0.84), 0 0 38px rgba(30, 132, 242, 0.88),
      0 0 60px rgba(30, 132, 242, 1);
  }
}

.link {
  /* position: absolute; */
  bottom: 10px;
  left: 10px;
  color: #828282;
  text-decoration: none;

  &:focus,
  &:hover {
    color: #c6e2ff;
    text-shadow: 0 0 2px rgba(202, 228, 225, 0.92),
      0 0 10px rgba(202, 228, 225, 0.34), 0 0 4px rgba(30, 132, 242, 0.52),
      0 0 7px rgba(30, 132, 242, 0.92), 0 0 11px rgba(30, 132, 242, 0.78),
      0 0 16px rgba(30, 132, 242, 0.92);
  }
}

@media screen and (max-width: 480px) {
  .background {
    padding: 200px 20px 100px 0px;
    text-align: center;
  }

  .button {
    display: block;
    margin: 10px 10px 10px 20px;
  }
}
