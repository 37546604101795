.home {
  position: relative;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
  background-color: black;
}

.video {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.videoThumb {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0px;
  transition: opacity 400ms ease 0ms;
  filter: blur(10px);
  transform: scale(1.1);
}

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.SubContent {
  text-align: center;
  padding: 10px;
}
.SubContent p {
  color: #eeeeeead;
  font-size: 1.3rem;
}
.SubContent button {
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 0px;
}
.SubContent button:hover {
  background-color: #f1f1f1;
  color: black;
  border: none;
}

@media screen and (max-width: 480px) {
  .Content {
    visibility: hidden;
  }
  .SubContent {
    visibility: hidden;
  }
}

@media only screen and (max-width: 768px) {
  .Content {
    /* margin-top: 100px; */
    height: 225px;
  }
  .video {
    margin-top: 150px;
  }

  .SubContent button {
    margin-top: 0px;
  }
  .SubContent p {
    font-size: 1rem;
  }
}
