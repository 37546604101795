.nav {
  padding: 10px;
}

.logo {
  height: 50px;
  width: auto;
}

.socials {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  width: 35px;
  padding: 5px;
}

.socialsContainer{
text-align:center;
}
@media screen and (min-width: 800px) {
  .socials {
    margin: 7px;
  }
}

@media screen and (max-width: 480px) {
  .logo {
    margin-right: -3em;
  }
  .navbar {
    background: black;
  }
  
}
